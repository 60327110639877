/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%;touch-action:pan-y}.flickity-slider{position:absolute;width:100%;height:100%;left:0}.flickity-rtl .flickity-slider{left:unset;right:0}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:grabbing}.flickity-cell{position:absolute;left:0}.flickity-rtl .flickity-cell{left:unset;right:0}.flickity-button{position:absolute;background:hsl(0 0% 100% / 75%);border:none;color:#333}.flickity-button:hover{background:#fff;cursor:pointer}.flickity-button:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-button:active{opacity:.6}.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}.flickity-button-icon{fill:currentColor}.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;transform:translateY(-50%)}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;text-align:center;display:flex;justify-content:center;flex-wrap:wrap}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dot{display:block;width:10px;height:10px;padding:0;margin:0 8px;background:hsl(0 0% 20% / 25%);border-radius:50%;cursor:pointer;appearance:none;border:none;text-indent:-9999px;overflow:hidden}.flickity-rtl .flickity-page-dot{text-indent:9999px}.flickity-page-dot:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-page-dot.is-selected{background:hsl(0 0% 20% / 100%)}
pre code.hljs{display:block;overflow-x:auto;padding:1em}code.hljs{padding:3px 5px}.hljs{background:#1e1e1e;color:#dcdcdc}.hljs-keyword,.hljs-literal,.hljs-name,.hljs-symbol{color:#569cd6}.hljs-link{color:#569cd6;text-decoration:underline}.hljs-built_in,.hljs-type{color:#4ec9b0}.hljs-class,.hljs-number{color:#b8d7a3}.hljs-meta .hljs-string,.hljs-string{color:#d69d85}.hljs-regexp,.hljs-template-tag{color:#9a5334}.hljs-formula,.hljs-function,.hljs-params,.hljs-subst,.hljs-title{color:#dcdcdc}.hljs-comment,.hljs-quote{color:#57a64a;font-style:italic}.hljs-doctag{color:#608b4e}.hljs-meta,.hljs-meta .hljs-keyword,.hljs-tag{color:#9b9b9b}.hljs-template-variable,.hljs-variable{color:#bd63c5}.hljs-attr,.hljs-attribute{color:#9cdcfe}.hljs-section{color:gold}.hljs-emphasis{font-style:italic}.hljs-strong{font-weight:700}.hljs-bullet,.hljs-selector-attr,.hljs-selector-class,.hljs-selector-id,.hljs-selector-pseudo,.hljs-selector-tag{color:#d7ba7d}.hljs-addition{background-color:#144212;display:inline-block;width:100%}.hljs-deletion{background-color:#600;display:inline-block;width:100%}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@keyframes bp-fadein {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 1;
  }
}
@keyframes bp-bar {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bp-o {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  /* If you're mounting on a portion of the screen and need visible
  overflow on .bp-wrap, change contain to "layout" on that instance */
  contain: strict;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}
.bp-wrap > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  animation: bp-fadein 0.48s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.bp-vid audio {
  position: absolute;
  left: 14px;
  width: calc(100% - 28px);
  bottom: 14px;
  height: 50px;
}

.bp-inner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
}

.bp-html {
  display: contents;
}
.bp-html > :first-child {
  margin: auto;
}

.bp-img-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  contain: strict;
}
.bp-img-wrap .bp-canzoom {
  cursor: zoom-in;
}
.bp-img-wrap .bp-drag {
  cursor: grabbing;
}

.bp-close {
  contain: layout size;
}

.bp-img {
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  background-size: 100% 100%;
}
.bp-img img,
.bp-img div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bp-img .bp-o {
  display: none;
}

.bp-zoomed .bp-img:not(.bp-drag) {
  cursor: grab;
}
.bp-zoomed .bp-cap {
  opacity: 0;
  animation: none !important;
}

.bp-zoomed.bp-small .bp-controls {
  opacity: 0;
}
.bp-zoomed.bp-small .bp-controls button {
  pointer-events: none;
}

.bp-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-align: left;
  transition: opacity 0.3s;
  animation: bp-fadein 0.3s;
}
.bp-controls button {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  border: 0;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  transition: all 0.1s;
  contain: content;
}
.bp-controls button:hover {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.bp-controls svg {
  fill: #fff;
}

.bp-count {
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1;
  margin: 16px;
  height: 50px;
  width: 100px;
}

.bp-prev,
.bp-next {
  top: 50%;
  right: 0;
  margin-top: -32px;
  height: 64px;
  width: 58px;
  border-radius: 3px 0 0 3px;
}
.bp-prev:hover:before,
.bp-next:hover:before {
  transform: translateX(-2px);
}
.bp-prev:before,
.bp-next:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/%3E%3C/svg%3E");
  position: absolute;
  left: 7px;
  top: 9px;
  width: 46px;
  transition: all 0.2s;
}

.bp-prev {
  right: auto;
  left: 0;
  transform: scalex(-1);
}

.bp-x {
  top: 0;
  right: 0;
  height: 55px;
  width: 58px;
  border-radius: 0 0 0 3px;
}
.bp-x:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23fff'%3E%3Cpath d='M24 10l-2-2-6 6-6-6-2 2 6 6-6 6 2 2 6-6 6 6 2-2-6-6z'/%3E%3C/svg%3E");
  position: absolute;
  width: 37px;
  top: 8px;
  right: 10px;
}

.bp-if,
.bp-vid {
  position: relative;
  margin: auto;
  background: #000;
  background-size: 100% 100%;
}
.bp-if iframe,
.bp-if video,
.bp-if div,
.bp-vid iframe,
.bp-vid video,
.bp-vid div {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
}

.bp-load {
  display: flex;
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 1;
}

.bp-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  transform: translateX(-100%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0 3px 3px 0;
  animation: bp-bar 4s both;
}

.bp-o,
.bp-o:after {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

.bp-o {
  margin: auto;
  border: 10px solid rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.9);
  animation: bp-o 1s infinite linear;
}

.bp-cap {
  position: absolute;
  bottom: 2%;
  background: rgba(9, 9, 9, 0.8);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  max-width: 95%;
  line-height: 1.3;
  padding: 0.6em 1.2em;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  width: -moz-fit-content;
  display: table;
  transition: opacity 0.3s;
  animation: bp-fadein 0.2s;
}
.bp-cap a {
  color: inherit;
}

.bp-inline {
  position: absolute;
}

.bp-lock {
  overflow-y: hidden;
}
.bp-lock body {
  overflow: scroll;
}

.bp-noclose .bp-x {
  display: none;
}
.bp-noclose:not(.bp-zoomed) {
  touch-action: pan-y;
}
.bp-noclose:not(.bp-zoomed) .bp-img-wrap {
  cursor: zoom-in;
}

@media (prefers-reduced-motion) {
  .bp-wrap * {
    animation-duration: 0s !important;
  }
}
@media (max-width: 500px) {
  .bp-x {
    height: 47px;
    width: 47px;
  }
  .bp-x:before {
    width: 34px;
    top: 6px;
    right: 6px;
  }

  .bp-prev,
.bp-next {
    margin-top: -27px;
    height: 54px;
    width: 45px;
  }
  .bp-prev:before,
.bp-next:before {
    top: 7px;
    left: 2px;
    width: 43px;
  }

  .bp-o,
.bp-o:after {
    border-width: 6px;
    width: 60px;
    height: 60px;
  }

  .bp-count {
    margin: 12px 10px;
  }
}


